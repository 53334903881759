<script>
import { defineComponent, reactive, computed, watchEffect } from "vue";

import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import Paginator from "@/components/paginator/Paginator";
import Swal from "@/plugins/swal";

export default defineComponent({
  name: "TeamMembers",
  components: { Paginator },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      teamId: route.params.id,
      pagination: {
        currentPage: route.query.page || 1,
        offset: 0,
        meta: {
          total: 0
        }
      },
      invites: []
    });

    function onPage(event) {
      state.pagination.currentPage = event.page + 1;
    }

    function remove(deny_token) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        confirmButtonText: "Yes, delete it!",
        showCancelButton: true,
        cancelButtonText: "Cancel"
      }).then(r => {
        if (r.isConfirmed) {
          store
            .dispatch("team/declineInvite", {
              teamId: state.teamId,
              deny_token
            })
            .then(() => {
              const index = state.invites.findIndex(
                p => p.deny_token === deny_token
              );
              if (index !== -1) {
                state.invites.splice(index, 1);
              }

              Swal.fire("Deleted!", "Invite has been declined.", "success");
            });
        }
      });
    }

    watchEffect(() => {
      store
        .dispatch("team/getInvites", {
          teamId: state.teamId,
          page: state.pagination.currentPage
        })
        .then(r => {
          state.pagination.meta = r.meta;
          state.invites = r.data;
          router.push({
            name: "dashboard.team.invites",
            query: { page: state.pagination.currentPage }
          });
        });
    });

    return {
      invites: computed(() => state.invites),
      pagination: state.pagination,
      onPage,
      remove
    };
  }
});
</script>

<template>
  <section
    class="px-4 sm:px-6 lg:px-4 xl:px-6 pt-4 pb-4 sm:pb-6 lg:pb-4 xl:pb-6 space-y-4"
  >
    <form class="relative">
      <svg
        width="20"
        height="20"
        fill="currentColor"
        class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
        />
      </svg>
      <input
        class="focus:border-light-blue-500 focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-full text-sm text-black placeholder-gray-500 border border-gray-200 rounded-md py-2 pl-10"
        type="text"
        aria-label="Filter invites"
        placeholder="Filter invites"
      >
    </form>
    <table class="table table-report -mt-2">
      <thead>
        <tr>
          <th class="whitespace-no-wrap" />
          <th class="whitespace-no-wrap">
            Email
          </th>
          <th class="text-center whitespace-no-wrap">
            Created
          </th>
          <th class="text-center whitespace-no-wrap" />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="invite in invites"
          :key="invite.id"
          class="intro-x"
        >
          <td class="w-10">
            <i class="ri-mail-fill" />
          </td>
          <td>
            {{ invite.email }}
          </td>
          <td class="text-center">
            {{ invite.created_at }}
          </td>
          <td class="table-report__action w-56">
            <div class="flex justify-center items-center">
              <base-button
                title="Resend invite"
                variant="bg-blue-600 mr-1"
                icon="ri-mail-send-line"
                size="button--sm"
              />
              <base-button
                title="Decline invite"
                variant="bg-red-600"
                icon="ri-delete-bin-line"
                size="button--sm"
                @click.prevent="remove(invite.deny_token)"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="pagination.meta.total"
      class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-no-wrap items-center"
    >
      <Paginator
        template="PrevPageLink PageLinks NextPageLink"
        :rows="10"
        :total-records="pagination.meta.total"
        @page="onPage($event)"
      />
    </div>
    <div v-else>
      <p class="font-medium text-lg text-center">
        Looks like team don't have any invitation!
      </p>
    </div>
  </section>
</template>
